import judgesActions from "../actions/judgesAction";

const initState = {
    judges: [],
    loading:false,
    current:null
};
export default function judgesReducer(state = initState, {type, payload, ...action}) {
    switch (type) {
        case judgesActions.ADD_JUDGES_DATA: {
            return {...state, judges: [...state.judges, payload.data],loading:false}
        }
        case judgesActions.REMOVE_JUDGES_DATA: {
            return {...state, judges: state.judges.filter(a => a.id !== payload)}
        }
        case judgesActions.SET_JUDGES_DATA: {
            return {...state, judges: payload,loading:false}
        }
        case judgesActions.LOADING_JUDGES: {
            return {...state, loading: true}
        }
        case judgesActions.REPLACE_JUDGES_DATA: {
            return {...state, judges: state.judges.map(a => a.id === payload.id ? payload : a)}
        }
        case judgesActions.CURRENT_JUDGE: {
            console.log(payload)
            return {...state, current: state.judges.find(a => a.id == payload)}
        }
        default:
            return state
    }
}
