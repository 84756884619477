const authActions = {
    LOGIN: 'LOGIN',
    LOGGED_IN: 'LOGGED_IN',
    LOGOUT: 'LOGOUT',
    LOGGED_OUT: 'LOGGED_OUT',
    RESET_PASSWORD: 'RESET_PASSWORD',
    LOADING: 'LOADING',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PROFILE_DATA: 'UPDATE_PROFILE_DATA',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    SIGN_UP: 'SIGN_UP',
    login :(data) => ({type:authActions.LOGIN, payload:data}),
    logout :() => ({type:authActions.LOGGED_OUT}),
    resetPassword :(password) => ({type:authActions.RESET_PASSWORD,payload:password}),
    updateProfile :(data) => ({type:authActions.UPDATE_PROFILE,payload:data}),
    forgotPassword :(data) => ({type:authActions.FORGOT_PASSWORD,payload:data}),
    signUp :(data) => ({type:authActions.SIGN_UP,payload:data})
};
export default authActions;
