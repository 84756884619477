const eventActions = {
    CREATE_EVENT: 'CREATE_EVENT',
    SET_EVENTS_DATA: 'SET_EVENTS_DATA',
    GET_EVENTS: 'GET_EVENTS',
    DELETE_EVENT: 'DELETE_EVENT',
    UPDATE_EVENT: 'UPDATE_EVENT',
    ADD_EVENT_DATA: 'ADD_EVENT_DATA',
    REMOVE_EVENT_DATA: 'REMOVE_EVENT_DATA',
    LOADING_EVENT: 'LOADING_EVENT',
    CURRENT_EVENT: 'CURRENT_EVENT',
    REPLACE_EVENT_DATA: 'REPLACE_EVENT_DATA',
    create:(data)=>({type:eventActions.CREATE_EVENT,payload:data}),
    deleteItem:(id)=>({type:eventActions.DELETE_EVENT,payload: id}),
    getCurrent:(id)=>({type:eventActions.CURRENT_EVENT,payload: id}),
    update:(data)=>({type:eventActions.UPDATE_EVENT,payload: data}),
    get:()=>({type:eventActions.GET_EVENTS})
};
export default eventActions;
