import './App.css';
import Router from "./router";
import './helpers/proto'
import React from "react";
import 'antd/dist/antd.css';
function App() {
  return (
    <Router/>
  );
}

export default App;
