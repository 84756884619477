import {combineReducers} from 'redux';
import authReducer from "./authReducer";
import eventGroupReducer from "./eventGroupreducer";
import eventReducer from "./eventReducer";
import judgesReducer from "./judgesReducer";

export default combineReducers({
    auth:authReducer,
    events:eventReducer,
    eventGroups: eventGroupReducer,
    judges:judgesReducer
});
