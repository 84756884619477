import {all, takeEvery, put, call, take, delay} from 'redux-saga/effects';
import actions from '../actions/eventActions';
import {events} from "../../data";

const getEventAPI = () => {
    return events
};

export function* createEvent({payload}) {
    try {
        yield put({type: actions.ADD_EVENT_DATA, payload: payload})
    } catch (e) {
        console.log(e)
    }
};

export function* deleteEvent({payload}) {
    try {
        // const data = yield call(deleteEventAPI, payload);
        yield put({type: actions.REMOVE_EVENT_DATA, payload: payload.id})
    } catch (e) {
        console.log(e)
    }
};

export function* updateEvent({payload}) {
    try {
        // const data = yield call(deleteEventAPI, payload);
        yield put({type: actions.REMOVE_EVENT_DATA, payload: payload.data})
    } catch (e) {
        console.log(e)
    }
};

export function* getEvent() {
    try {
        yield put({type: actions.LOADING_EVENT});
        const data = yield call(getEventAPI);
        yield delay(1000);
        yield put({type: actions.SET_EVENTS_DATA, payload: data})
    } catch (e) {
        console.log(e)
    }
};

export default function* eventGroupSaga() {
    yield all([
        yield takeEvery(actions.CREATE_EVENT, createEvent),
        yield takeEvery(actions.GET_EVENTS, getEvent),
        yield takeEvery(actions.DELETE_EVENT, deleteEvent),
        yield takeEvery(actions.UPDATE_EVENT, updateEvent),
    ]);
}
