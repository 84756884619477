export const countries =  [{name:"India"}];
export const states =  [{name:"Kerala",country:"India"},{name:"Tamil Nadu",country:"India"}];
export const city =  [{name:"Coimbatore",state:"Kerala"},{name:"Salem",state:"amil Nadu"}];
export const eventGroups = [
    {
        id: 1,
        name: 'Doodle',
        participants: 24,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: true,
    },    {
        id: 5,
        name: 'Doodle',
        participants: 24,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: true,
    },    {
        id: 2,
        name: 'Doodle',
        participants: 24,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: false,
    },    {
        id: 3,
        name: 'Doodle',
        participants: 24,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: true,
    },    {
        id: 4,
        name: 'Doodle',
        participants: 24,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: true,
    },
];

export const events = [
    {
        id: 1,
        name: 'Doodle',
        participants: 24,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: true,
    },{
        id: 2,
        name: 'Skit',
        participants: 34,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: true,
    },{
        id: 3,
        name: 'Testing',
        participants: 44,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: false,
    },{
        id: 4,
        name: 'Coding',
        participants: 54,
        eventGroup: 'Carton',
        createdBy: 'Admin',
        createdAt: 'Feb 13/2021 - 10:30 PM',
        active: false,
    }
];
export const judges = [
    {
        id: 1,
        name: 'Aditya',
        email: 'aditya@yopmail.com',
        contact: '+919567682232',
        contact2: '+919446904024',
        country: 'India',
        state: 'Tamil Nadu',
        city: 'Salem',
        active: true,
    },{
        id: 2,
        name: 'Arjun',
        email: 'arjun@yopmail.com',
        contact: '+919567682232',
        contact2: '+919446904024',
        country: 'India',
        state: 'Tamil Nadu',
        city: 'Salem',
        active: true,
    },{
        id: 3,
        name: 'Rohit',
        email: 'rohit@yopmail.com',
        contact: '+919567682232',
        contact2: '+919446904024',
        country: 'India',
        state: 'Tamil Nadu',
        city: 'Salem',
        active: true,
    },
];

export const users = [{
    id:1,
    name:"Aditya V",
    avatar:"https://avatars.githubusercontent.com/u/25718001?s=460&u=76a87269a43a9d0b0032b5db8bc801a2594d04e7&v=4",
    password:"ASdfg12!",
    email:"mail@adityav.in",
    phone:'9567682232',
    gender:'male',
    role:'Admin',
    address:'No 24 , Gandhi Nagar,2nd Street'
}];
