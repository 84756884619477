const eventGroupActions = {
    CREATE_EVENT_GROUP: 'CREATE_EVENT_GROUP',
    SET_EVENT_GROUPS_DATA: 'SET_EVENT_GROUPS_DATA',
    GET_EVENT_GROUPS: 'GET_EVENT_GROUPS',
    DELETE_EVENT_GROUPS: 'DELETE_EVENT_GROUPS',
    UPDATE_EVENT_GROUPS: 'UPDATE_EVENT_GROUPS',
    ADD_EVENT_GROUPS_DATA: 'ADD_EVENT_GROUPS_DATA',
    REMOVE_EVENT_GROUPS_DATA: 'REMOVE_EVENT_GROUPS_DATA',
    REPLACE_EVENT_GROUPS_DATA: 'REPLACE_EVENT_GROUPS_DATA',
    CURRENT_EVENT_GROUP: 'CURRENT_EVENT_GROUP',
    LOADING_EVENT_GROUPS: 'LOADING_EVENT_GROUPS',
    create:(data)=>({type:eventGroupActions.CREATE_EVENT_GROUP,payload:data}),
    deleteItem:(id)=>({type:eventGroupActions.DELETE_EVENT_GROUPS,payload: id}),
    getCurrent:(id)=>({type:eventGroupActions.CURRENT_EVENT_GROUP,payload: id}),
    update:(data)=>({type:eventGroupActions.UPDATE_EVENT_GROUPS,payload: data}),
    get:()=>({type:eventGroupActions.GET_EVENT_GROUPS})
};

export default eventGroupActions;
