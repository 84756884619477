import {all, takeEvery, put, call, take, delay} from 'redux-saga/effects';
import actions from '../actions/judgesAction';
import { judges} from "../../data";

const getJudgeAPI = () => {
    return judges
};

export function* createJudge({payload}) {
    try {
        yield put({type: actions.ADD_JUDGES_DATA, payload: payload})
    } catch (e) {
        console.log(e)
    }
};

export function* deleteJudge({payload}) {
    try {
        // const data = yield call(deleteEventAPI, payload);
        yield put({type: actions.REMOVE_JUDGES_DATA, payload: payload.id})
    } catch (e) {
        console.log(e)
    }
};

export function* updateJudge({payload}) {
    try {
        // const data = yield call(deleteEventAPI, payload);
        yield put({type: actions.REPLACE_JUDGES_DATA, payload: payload.data})
    } catch (e) {
        console.log(e)
    }
};

export function* getJudge() {
    try {
        yield put({type: actions.LOADING_JUDGES});
        const data = yield call(getJudgeAPI);
        yield delay(1000);
        yield put({type: actions.SET_JUDGES_DATA, payload: data})
    } catch (e) {
        console.log(e)
    }
};

export default function* eventGroupSaga() {
    yield all([
        yield takeEvery(actions.CREATE_JUDGES, createJudge),
        yield takeEvery(actions.GET_JUDGES, getJudge),
        yield takeEvery(actions.DELETE_JUDGES, deleteJudge),
        yield takeEvery(actions.UPDATE_JUDGES, updateJudge),
    ]);
}
