import React, {Component} from 'react';

class ErrorBoundary extends Component {
    state = {
        errorMessage: ''
    };
    static getDerivedStateFromError(error) {
        console.log(error);
        return {errorMessage: error.toString()}
    }
    componentDidCatch(error, info) {
        console.log(error);
    }
    render() {
        if (this.state.errorMessage) {
            return (
                <h4 style={style}>
                    {this.state.errorMessage}
                </h4>
            )
        }
        return this.props.children
    }
}
var style = {
    "textAlign":'center',
    "display":'flex',
    "alignItems":'center',
    "color":'red',
    'justifyContent':'center',
    'height':'100vh'
}
export default ErrorBoundary;
