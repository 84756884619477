const judgesActions = {
    CREATE_JUDGES: 'CREATE_JUDGES',
    SET_JUDGES_DATA: 'SET_JUDGES_DATA',
    GET_JUDGES: 'GET_JUDGES',
    DELETE_JUDGES: 'DELETE_JUDGES',
    UPDATE_JUDGES: 'UPDATE_JUDGES',
    ADD_JUDGES_DATA: 'ADD_JUDGES_DATA',
    REMOVE_JUDGES_DATA: 'REMOVE_JUDGES_DATA',
    REPLACE_JUDGES_DATA: 'REPLACE_JUDGES_DATA',
    LOADING_JUDGES:'LOADING_JUDGES',
    CURRENT_JUDGE:'CURRENT_JUDGE',
    create: (data) => ({type: judgesActions.CREATE_JUDGES, payload: data}),
    deleteItem: (id) => ({type: judgesActions.DELETE_JUDGES, payload: id}),
    getCurrent: (id) => ({type: judgesActions.CURRENT_JUDGE, payload: id}),
    update: (data) => ({type: judgesActions.UPDATE_JUDGES, payload: data}),
    get: () => ({type: judgesActions.GET_JUDGES})
};

export default judgesActions;

