import actions from "../actions/authActions";
import Storage from "../../helpers/storage";
import {notification} from 'antd';

const defaultUser = {
    name: "",
    email: '',
    profile: ""
};
const user = Storage.getItem('user') || defaultUser;
const initState = {
    user,
    loading: false
};
export default function authReducer(state = initState, {type, payload, ...action}) {
    switch (type) {
        case actions.LOGGED_IN: {
            Storage.setItem('user', payload);
            return {...state, user: payload};
        }
        case actions.LOADING: {
            return {
                ...state, loading: true
            }
        }
        case actions.LOGGED_OUT: {
            Storage.clearAll();
            notification.success({message:"SuccessFullly Logged Out"});
            return {
                ...state, user: initState.user
            }
        }
        case actions.UPDATE_PROFILE_DATA: {
            console.log(payload, 'payload');
            const user = {...state.user, ...payload};
            Storage.setItem('user', user);
            return {
                ...state, user
            }
        }
        default:
            return state
    }
}
