import {createStore, applyMiddleware} from 'redux';
import rootReducer from './redux/reducers';
import createSagaMiddleware from 'redux-saga';
import "regenerator-runtime/runtime";
import rootSaga from './redux/saga';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== 'production') {
        const {composeWithDevTools} = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};


const store = createStore(rootReducer, bindMiddleware(middlewares));
sagaMiddleware.run(rootSaga);
export {store};
