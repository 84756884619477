import {all, takeEvery, put, call, take} from 'redux-saga/effects';
import actions from '../actions/authActions';
import {users} from "../../data";
import { notification } from 'antd';

const loginAPI = ({email,password}) =>{
    const user = users.find(a=>a.email===email&&a.password===password);
    if(user){
        return {data:user,message:'Successfull !'}
    }else{
        throw new Error("User Not Found");
    }

};

export function* login({payload}){
   try{
       const {data,message} = yield call(loginAPI, payload);
       yield put({type:actions.LOGGED_IN,payload:data});
       notification.success({message})
   }catch (e) {
       notification.error({message:e.message});
   }
}
export function* resetPassword({payload}){
   try{
       // const {data,message} = yield call(loginAPI, payload);
       // yield put({type:actions.LOGGED_IN,payload:data});
       notification.success({message: "Password Updated !"})
   }catch (e) {
       notification.error({message:e.message});
   }
}
export function* updateUser({payload}){
   try{
       // const {data,message} = yield call(loginAPI, payload);
       yield put({type:actions.UPDATE_PROFILE_DATA,payload:payload});
       notification.success({message: "User Data Updated !"})
   }catch (e) {
       notification.error({message:e.message});
   }
}
export function* signup({payload}){
   try{
       // const {data,message} = yield call(loginAPI, payload);
       // yield put({type:actions.UPDATE_PROFILE_DATA,payload:payload});
       notification.success({message: "Signup Successfull !"})
   }catch (e) {
       notification.error({message:e.message});
   }
}
export function* forgotPassword({payload}){
   try{
       // const {data,message} = yield call(loginAPI, payload);
       // yield put({type:actions.UPDATE_PROFILE_DATA,payload:payload});
       notification.success({message: "Forgot Password !"})
   }catch (e) {
       notification.error({message:e.message});
   }
}

export default function* authSaga() {
    yield all([
        takeEvery(actions.LOGIN, login),
        takeEvery(actions.RESET_PASSWORD, resetPassword),
        takeEvery(actions.UPDATE_PROFILE, updateUser),
        takeEvery(actions.SIGN_UP, signup),
        takeEvery(actions.FORGOT_PASSWORD, forgotPassword)
    ]);
}
