import { all } from 'redux-saga/effects';
import authSage from './authSaga';
import eventSaga from "./eventSaga";
import eventGroupSaga from "./eventGroupSaga";
import judgesSaga from "./judgesSaga";
export default function* rootSaga() {

    yield all([
        authSage(),
        eventSaga(),
        eventGroupSaga(),
        judgesSaga()
    ]);
}
