import {all, takeEvery, put, call, take,delay} from 'redux-saga/effects';
import actions from '../actions/eventGroupActions';
import {eventGroups} from "../../data";


const getEventGroupAPI = () => {
    return eventGroups
};
const deleteEventAPI = ({id}) => {
    // return eventGroups.filter(a => a.id !== id)
};

export function* createEventGroup({payload}) {
    try {
        yield put({type: actions.ADD_EVENT_GROUPS_DATA, payload: payload})
    } catch (e) {
        console.log(e)
    }
};

export function* deleteEventGroup({payload}) {
    try {
        // const data = yield call(deleteEventAPI, payload);
        yield put({type: actions.REMOVE_EVENT_GROUPS_DATA, payload: payload.id})
    } catch (e) {
        console.log(e)
    }
};

export function* updateEventGroup({payload}) {
    try {
        // const data = yield call(deleteEventAPI, payload);
        yield put({type: actions.REMOVE_EVENT_GROUPS_DATA, payload: payload.data})
    } catch (e) {
        console.log(e)
    }
};

export function* getEventGroup() {
    try {
        yield put({type: actions.LOADING_EVENT_GROUPS});
        const data = yield call(getEventGroupAPI);
        yield delay(1000);
        yield put({type: actions.SET_EVENT_GROUPS_DATA, payload: data})
    } catch (e) {
        console.log(e)
    }
};

export default function* eventGroupSaga() {
    yield all([
        yield takeEvery(actions.CREATE_EVENT_GROUP, createEventGroup),
        yield takeEvery(actions.GET_EVENT_GROUPS, getEventGroup),
        yield takeEvery(actions.DELETE_EVENT_GROUPS, deleteEventGroup),
        yield takeEvery(actions.UPDATE_EVENT_GROUPS, updateEventGroup),
    ]);
}
