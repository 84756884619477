import React from 'react';
const PageNotFound = () => {
    return (
        <div>

            <div className="container">
                <div className="logo-dark">
                    <img src="../assets/images/logo-dark.png" alt="dark logo"/>
                </div>
            </div>
            <div className="error-page text-center">
                <div className="page-not-found">
                    <img src="../assets/images/404-error.jpg" alt="404"/>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
