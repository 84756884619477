class Storage {
    setItem(key,data){
        localStorage.setItem(key,JSON.stringify(data))
    }
    getItem(key){
       let data =  localStorage.getItem(key);
       return JSON.parse(data);
    }
    deleteItem(key){
        localStorage.removeItem(key);
    }
    clearAll(){
        localStorage.clear();
    }
}
export default new Storage();
