import React, {Component, lazy, Suspense} from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import {createBrowserHistory} from "history";
import {Provider} from 'react-redux';
import {store} from './store';
import ErrorBoundary from "./components/ErrorBoundary";
import PageNotFound from "./pages/PagenotFound";
const browserHistory = createBrowserHistory({queryKey: false});


const routes = [
    {
        path: '/',
        component: lazy(() => import('./pages/login-page/Login')),
        exact: true,
    },{
        path: '/profile',
        component: lazy(() => import('./pages/profile-page/Profile')),
        exact: true,
    },{
        path: '/forgot',
        component: lazy(() => import('./pages/forgot-password/ForgotPassword')),
        exact: true,
    },{
        path: '/signup',
        component: lazy(() => import('./pages/signup-page/SignupPage')),
        exact: true,
    },{
        path: '/judges',
        component: lazy(() => import('./pages/judges-page/Judges')),
        exact: true,
    },{
        path: '/event-group',
        component: lazy(() => import('./pages/event-group-page/EventGroup')),
        exact: true,
    },{
        path: '/event',
        component: lazy(() => import('./pages/event-page/Event')),
        exact: true,
    },{
        path: '/event-group/add',
        component: lazy(() => import('./pages/add-event-group-page/AddEventGroup')),
        exact: true,
    },{
        path: '/judges/add',
        component: lazy(() => import('./pages/add-judges-page/AddJudges')),
        exact: true,
    },{
        path: '/event/add',
        component: lazy(() => import('./pages/add-event-page/AddEvent')),
        exact: true,
    },{
        path: '/event-group/edit/:id',
        component: lazy(() => import('./pages/add-event-group-page/AddEventGroup')),
        exact: true,
    },{
        path: '/judges/edit/:id',
        component: lazy(() => import('./pages/add-judges-page/AddJudges')),
        exact: true,
    },{
        path: '/event/edit/:id',
        component: lazy(() => import('./pages/add-event-page/AddEvent')),
        exact: true,
    }
    ];
class Router extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter history={browserHistory}>
                    <ErrorBoundary>
                        <Suspense fallback={<h4>Loading....</h4>}>
                            <Switch>
                                {routes.map((route, idx) => (
                                    <Route exact={route.exact} key={idx} path={`${route.path}`}>
                                            <route.component/>
                                    </Route>
                                ))}
                                <Route component={PageNotFound}/>
                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default Router;
