import eventGroupActions from "../actions/eventGroupActions";

const initState = {
    eventGroups: [],
    loading:false,
    current:null
};
export default function eventGroupReducer(state = initState, {type, payload, ...action}) {
    switch (type) {
        case eventGroupActions.ADD_EVENT_GROUPS_DATA: {
            return {...state, eventGroups:[...state.eventGroups,payload],loading: false}
        }
        case eventGroupActions.REMOVE_EVENT_GROUPS_DATA: {
            return {...state, eventGroups: state.eventGroups.filter(a => a.id !== payload)}
        }
        case eventGroupActions.SET_EVENT_GROUPS_DATA: {
            return {...state, eventGroups: payload,loading: false}
        }
        case eventGroupActions.REPLACE_EVENT_GROUPS_DATA: {
            return {...state, eventGroups: state.eventGroups.map(a => a.id === payload.id ? payload : a)}
        }
        case eventGroupActions.LOADING_EVENT_GROUPS: {
            return {...state, loading: true}
        }
        case eventGroupActions.CURRENT_EVENT_GROUP: {
            return {...state, current: state.eventGroups.find(a => a.id == payload)}
        }
        default:
            return state
    }
}
