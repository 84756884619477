import eventActions from "../actions/eventActions";

const initState = {
    events: [],
    loading:false,
    current:null
};
export default function eventReducer(state = initState, {type, payload, ...action}) {
    switch (type) {
        case eventActions.ADD_EVENT_DATA: {
            return {...state, events: [...state.events, payload],loading:false}
        }
        case eventActions.REMOVE_EVENT_DATA: {
            return {...state, events: state.events.filter(a => a.id !== payload)}
        }
        case eventActions.SET_EVENTS_DATA: {
            return {...state, events: payload,loading:false}
        }
        case eventActions.REPLACE_EVENT_DATA: {
            return {...state, events: state.events.map(a => a.id === payload.id ? payload : a)}
        }
        case eventActions.LOADING_EVENT: {
            return {...state, loading: true}
        }
        case eventActions.CURRENT_EVENT: {
            return {...state, current: state.events.find(a => a.id == payload)}
        }
        default:
            return state
    }
}
